<template>
  <div class="flex h-full">
    <loader :loading="loading" :backdrop="true" />
    <div class="flex-none border-r-2 border-gray-400 pr-8 space-y-6">
      <div class="form-row">
        <p class="font-bold text-sm">banner position</p>
      </div>
      <form>
        <input type="radio" value="left" v-model="position" />
        <label for="left"> float bottom left</label><br />
        <input type="radio" value="right" v-model="position" />
        <label for="right"> float bottom right</label><br />
      </form>
      <div class="form-row">
        <p class="font-bold text-sm">banner type</p>
      </div>
      <form>
        <input type="radio" value="circled" v-model="type" />
        <label for="left"> circled</label><br />
        <input type="radio" value="squared" v-model="type" />
        <label for="right"> squared</label><br />
      </form>
      
      <div class="form-row">
        <p class="font-bold text-sm">background color</p>
      </div>
      <!-- <form>
        <input type="checkbox" @change="onTransparentColorChange($event)" v-model="colorIsTransparent" />
        <label for="left"> transparent</label><br />
      </form>       -->
      <ColorPicker 
        :color="color"
        :visible-formats="['hex']"
        default-format="hex"
        alpha-channel="hide"
        @color-change="onColorChange"
      />       

      <div class="form-row">
        <p class="font-bold text-sm">banner size</p>
      </div>
      <div class="flex space-x-2">
        <div class="flex-none">
          <span class="text-sm"> smaller </span>
        </div>
        <div class="flex-1">
          <input
            type="range"
            min="100"
            max="350"
            v-model="width"
            class="slider"
            id="myRange"
            style="width: 200px"
          />
          <center>
            <span>{{ width }}</span>
          </center>
        </div>
        <div class="flex-none">
          <span class="text-sm"> larger </span>
        </div>
      </div>
      <div class="text-center">
        <button
          class="
            btn-primary
            inline
            cursor-pointer
            font-semibold
            focus:outline-none
            banner-button
          "
          @click="copyToClipboard"
        >
          copy to clipboard
        </button>
      </div>
      <div class="text-center">
        <a
          class="text-sm underline"
          :href="howToURL"
          target="_blank"
          style="color: var(--highlightColor500)"
        >
          how can I add this to my website?
        </a>
      </div>
    </div>
    <div class="flex-1" style="position:relative">
        <center style="position: absolute; left: 50%; transform: translateX(-50%); top: 14%">
          <a 
            :href="communityURL" 
            target="_blank" 
          >
            <div :style="'position: relative; width: ' + width + 'px' + '; height: ' + width + 'px'">
              <img
                  :src="this.baseURL + 'banners/mask.png'"
                  height="auto"
                  :style="stylesMaskBanner"
              />
              <img
                  :src="bannerTypeURL"
                  alt="Digital Human"
                  height="auto"
                  :style="stylesPersonaBanner"
              />
            </div>
          </a>
        </center>
    </div>
    <modal-footer :primary="save"></modal-footer>
  </div>
</template>
<script>
import AuthMixin from "@/components/auth/AuthMixin";
import NotifyMixin from "@/mixins/NotifyMixin";
import ModalNavigation from "@/mixins/ModalNavigation";
import getEnvVariable from "@/utils/getEnvVariable";
import Loader from "@/components/ui/Loader";
import ModalFooter from "@/components/ui/modals/ModalFooter";

import { ColorPicker } from 'vue-accessible-color-picker'


export default {
  name: "Banner",
  data() {
    return {
      loading: false,
      createSettingTries: 0,
      imageName: "dh_banner.png",
      
      width: 100,
      width_id: "",
      
      position: "right",
      position_id: "",

      type: "circled",
      type_id: "",

      color: "#cccccc",
      cachedColor: "#cccccc",
      color_id: "",      

      // colorIsTransparent: false,

      stylesPersonaBanner: {
        'background-color': 'transparent',
        'border-radius': '50%',
        'border': '1px solid #333333',
        'position': 'absolute',
        'top': '0',
        'width': '100%'
      },

      stylesMaskBanner: {
        'z-index': '100',
        'position': 'absolute',
        'top': '0',
        'margin-top': '-16%',
        'margin-left': '7%',
        'width': '100%'
      },      

      // Current persona is the selected persona in the General Settings
      digitalHumanPersona: 'mia',

      code: "",
      baseURL: "",
      settingsToUpdate: [],
    };
  },
  components: { Loader, ModalFooter,ColorPicker },
  mixins: [AuthMixin, NotifyMixin, ModalNavigation],
  computed: {
    communityURL() {
      return `${this.baseURL}?community_id=${this.community?.id}`;
    },
    bannerURL() {
      // const baseURL = getEnvVariable('VUE_APP_DIGITAL_HUMAN_AGENT_URL');
      return `${this.baseURL}${this.imageName}`;
    },
    bannerTypeURL() {

      let imageName = this.digitalHumanPersona + '.png';

      return `${this.baseURL}banners/${imageName}`;
    },    
    howToURL() {
      // const baseURL = getEnvVariable('VUE_APP_DIGITAL_HUMAN_AGENT_URL');
      return `${this.baseURL}how-to-banner.html`;
    },
  },
  methods: {
    copyToClipboard() {
      navigator.clipboard.writeText(this.code);
      this.notifySuccess("Copied to clipboard");
    },

    bannerStyleType(){

      if(this.type == 'circled'){
        this.stylesPersonaBanner['border-radius'] = '50%';
      }

      if(this.type == 'squared'){
        this.stylesPersonaBanner['border-radius'] = '0%';
      }
    },

    onColorChange(eventData){
      this.stylesPersonaBanner['background-color'] = eventData.cssColor;
      this.cachedColor = this.color = eventData.cssColor;
      this.bannerTemplate(this.position);
    },

    /**
     * This function is used for the user to export the html code
     */
    bannerTemplate(position){

      let styleLink;

      switch(position){
        case 'left':
          styleLink = "position:fixed;bottom:10px;left:10px;";
        break;
        case 'right':
          styleLink = "position:fixed;bottom:10px;right:10px;";
        break;
      }

      let styleContainer = 'position: relative; width: ' + this.width + 'px' + '; height: ' + this.width + 'px';

      let styleMask = "";
      for (const keyMask in this.stylesMaskBanner) {
          styleMask += `${keyMask}: ${this.stylesMaskBanner[keyMask]};`;
      }

      let stylePersona = "";
      for (const keyPersona in this.stylesPersonaBanner) {
          stylePersona += `${keyPersona}: ${this.stylesPersonaBanner[keyPersona]};`;
      }

      this.code = 
        `<a 
          href="${this.communityURL}" 
          target="_blank" 
          style="${styleLink}"
        >
          <div style="${styleContainer}">
            <img
              src="${this.baseURL}banners/mask.png"
              height="auto"
              style="${styleMask}"
            />              
            <img 
              src="${this.bannerTypeURL}" 
              alt="Digital Human" 
              height="auto"
              style="${stylePersona}"
            />
          </div>
        </a>`;
    },

    fetchAllCommunitySettings() {
      this.loading = true;
      this.$dhDataProvider
        .getList("allVariablesSettings", { communityId: this.community?.id })
        .then((res) => {
          this.variables = res;
          this.loadBannerSettings(res);
        })
        .catch((error) => this.notifyError(error.message))
        .finally(() => (this.loading = false));
    },
    loadBannerSettings(communitySettings) {

      if(communitySettings.PROPERTY_DIGITAL_HUMAN){
        this.digitalHumanPersona = communitySettings.PROPERTY_DIGITAL_HUMAN.setting_value;
      }

      let settingsThatNeedToBeUpdated = [];

      // Banner Position
      const communityHasBannerPosition = Object.hasOwn(
        communitySettings,
        "PROPERTY_BANNER_POSITION"
      );
      if(!communityHasBannerPosition){
        settingsThatNeedToBeUpdated.push(
          {
            setting_name: "{PROPERTY_BANNER_POSITION}",
            setting_value: "right",
          },          
        );
      }

      // Banner Width
      const communityHasBannerWidth = Object.hasOwn(
        communitySettings,
        "PROPERTY_BANNER_WIDTH"
      );
      if(!communityHasBannerWidth){
        settingsThatNeedToBeUpdated.push(
          {
                setting_name: "{PROPERTY_BANNER_WIDTH}",
                setting_value: "100",
          },          
        );
      }

      // Banner Type
      const communityHasBannerType = Object.hasOwn(
        communitySettings,
        "PROPERTY_BANNER_TYPE"
      );
      if(!communityHasBannerType){
        settingsThatNeedToBeUpdated.push(
          {
            setting_name: "{PROPERTY_BANNER_TYPE}",
            setting_value: "circled",
          },          
        );
      }

      // Banner Color
      const communityHasBannerColor = Object.hasOwn(
        communitySettings,
        "PROPERTY_BANNER_COLOR"
      );      
      if(!communityHasBannerColor){
        settingsThatNeedToBeUpdated.push(
          {
            setting_name: "{PROPERTY_BANNER_COLOR}",
            setting_value: "#cccccc",
          },          
        );
      }
      
      if (!settingsThatNeedToBeUpdated.length) {
        this.setBannerSettingValues(communitySettings);
      } else if (settingsThatNeedToBeUpdated.length && this.createSettingTries < 1) {
        this.createSettings(settingsThatNeedToBeUpdated);
        this.createSettingTries++;
      }

    },
    setBannerSettingValues(communitySettings) {

      //Banner width
      this.width = parseInt(
        communitySettings?.PROPERTY_BANNER_WIDTH.setting_value
      );
      this.width_id =
        communitySettings?.PROPERTY_BANNER_WIDTH.id_community_setting_value;

      //Banner position
      this.position = communitySettings?.PROPERTY_BANNER_POSITION.setting_value;
      this.position_id =
        communitySettings?.PROPERTY_BANNER_POSITION.id_community_setting_value;

      //Banner type
      this.type = communitySettings?.PROPERTY_BANNER_TYPE.setting_value;
      this.type_id =
        communitySettings?.PROPERTY_BANNER_TYPE.id_community_setting_value;  
        
      //Banner color
      this.color = communitySettings?.PROPERTY_BANNER_COLOR.setting_value;
      this.color_id =
        communitySettings?.PROPERTY_BANNER_COLOR.id_community_setting_value;        
    },
    createSettings(settingsThatNeedToBeUpdated) {

      this.loading = true;
      this.$dhDataProvider
        .create("communitySettings", {
          communityId: this.community?.id,
          data: {
            community_settings: settingsThatNeedToBeUpdated
          },
        })
        .then(() =>
          this.notifySuccess("Banner Settings were successfully created")
        )
        .catch((error) => this.notifyError(error.message))
        .finally(() => {
          this.fetchAllCommunitySettings();
          this.loading = false;
        });
    },
    save() {
      const position = {
        active: false,
        id_community_setting_value: this.position_id,
        setting_value: this.position,
      };
      const width = {
        active: false,
        id_community_setting_value: this.width_id,
        setting_value: this.width,
      };
      const type = {
        active: false,
        id_community_setting_value: this.type_id,
        setting_value: this.type,
      };
      const color = {
        active: false,
        id_community_setting_value: this.color_id,
        setting_value: this.color,
      };           

      this.settingsToUpdate.push(position);
      this.settingsToUpdate.push(width);
      this.settingsToUpdate.push(type);
      this.settingsToUpdate.push(color);

      this.loading = true;
      this.$dhDataProvider
        .update("communitySetting", {
          id: 0,
          data: this.settingsToUpdate,
        })
        .then(() =>
          this.notifySuccess("Banner Settings were updated successfully")
        )
        .catch((error) => this.notifyError(error.message))
        .finally(() => {
          this.settingsToUpdate = [];
          this.fetchAllCommunitySettings();
          this.loading = false;
        });
    },
  },
  watch: {

    position: function (val) {
      this.bannerTemplate(val);
    },

    width: function () {
      this.bannerTemplate(this.position);
    },

    type: function () {
      this.bannerStyleType();
      this.bannerTemplate(this.position);
    }
  },
  mounted() {
    this.bannerTemplate('right');
  },
  created() {
    this.settingsToUpdate = [];
    if (!this.community) {
      this.notifyError(
        "please select a community to continue, then refresh the browser"
      );
    } else {
      this.fetchAllCommunitySettings();
    }
    this.baseURL = getEnvVariable("VUE_APP_DIGITAL_HUMAN_AGENT_URL");
  },
};
</script>
<style>
.border-color {
  border-color: var(--highlightColor300);
}
.banner-button {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
</style>