<template>
  <form class="flex flex-col max-w-full">
    <loader :loading="loading" :backdrop="true" />
    <div class="h-full boxSize overflow-auto">
      <div class="form-row">
        <div class="form-col">
          <label for="aplication_fee">
            <input
              type="checkbox"
              class="mr-1 w-4 h-4"
              :checked="variables.APPLICATION_FEE.active"
              @change="() => {
                variables.APPLICATION_FEE.active = !variables.APPLICATION_FEE.active;
                handleChange(
                  variables.APPLICATION_FEE,
                  `$${aplication_fee}`,
                  variables.APPLICATION_FEE.active
                );
              }"
            />
            one-time application fee
          </label>
          <span class="inputField">
            <text-input value="$" readOnly/>
            <text-input
              step="1"
              type="number"
              class="w-11/12"
              inputClass="focus:outline-none"
              id="aplication_fee"
              :disabled="!variables.APPLICATION_FEE.active"
              v-model="aplication_fee"
              @change="handleChange(
                variables.APPLICATION_FEE,
                `$${aplication_fee}`,
                variables.APPLICATION_FEE.active
              )"
            />
          </span>
          <span class="form-hint">this is a one-time application fee of your community.</span>
          <span v-if="hasError('aplication_fee')" class="form-error">{{ showError('aplication_fee') }}</span>
        </div>
        <div class="form-col">
          <label for="administration_fee">
            <input
              type="checkbox"
              class="mr-1 w-4 h-4"
              :checked="variables.ADMINISTRATION_FEE.active"
              @change="() => {
                variables.ADMINISTRATION_FEE.active = !variables.ADMINISTRATION_FEE.active;
                handleChange(
                  variables.ADMINISTRATION_FEE,
                  `$${administration_fee}`,
                  variables.ADMINISTRATION_FEE.active
                );
              }"
            />
            one-time administration fee
          </label>
          <span class="inputField">
            <text-input value="$" readOnly/>
            <text-input
              type="number"
              step="1"
              class="w-11/12"
              inputClass="focus:outline-none"
              id="administration_fee"
              :disabled="!variables.ADMINISTRATION_FEE.active"
              v-model="administration_fee"
              @change="handleChange(
                variables.ADMINISTRATION_FEE,
                `$${administration_fee}`,
                variables.ADMINISTRATION_FEE.active
              )"
            />
          </span>
          <span class="form-hint">this is a one-time administration fee of your community.</span>
          <span v-if="hasError('administration_fee')" class="form-error">{{ showError('administration_fee') }}</span>
        </div>

        <div class="form-col">
          <label for="reserved_parking_fee">
            <input
              type="checkbox"
              class="mr-1 w-4 h-4"
              :checked="variables.RESERVED_PARKING_FEE.active"
              @change="() => {
                variables.RESERVED_PARKING_FEE.active = !variables.RESERVED_PARKING_FEE.active;
                handleChange(
                  variables.RESERVED_PARKING_FEE,
                  `$${reserved_parking_fee}`,
                  variables.RESERVED_PARKING_FEE.active
                );
              }"
            />
            monthly reserved parking fee
          </label>
          <span class="inputField">
            <text-input value="$" readOnly/>
            <text-input
              type="number"
              step="1"
              class="w-11/12"
              inputClass="focus:outline-none"
              id="reserved_parking_fee"
              :disabled="!variables.RESERVED_PARKING_FEE.active"
              v-model="reserved_parking_fee"
              @change="handleChange(
                variables.RESERVED_PARKING_FEE,
                `$${reserved_parking_fee}`,
                variables.RESERVED_PARKING_FEE.active
              )"
            />
          </span>
          <span class="form-hint">this is a monthly parking reservation fee for your community.</span>
          <span v-if="hasError('reserved_parking_fee')" class="form-error">{{ showError('reserved_parking_fee') }}</span>
        </div>
      </div>
      <div class="form-row">
        <div class="form-col">
          <label for="garage_parking_fee">
            <input
              type="checkbox"
              class="mr-1 w-4 h-4"
              :checked="variables.GARAGE_PARKING_FEE.active"
              @change="() => {
                variables.GARAGE_PARKING_FEE.active = !variables.GARAGE_PARKING_FEE.active;
                handleChange(
                  variables.GARAGE_PARKING_FEE,
                  `$${garage_parking_fee}`,
                  variables.GARAGE_PARKING_FEE.active
                );
              }"
            />
            monthly garage parking fee </label>
          <span class="inputField">
            <text-input value="$" readOnly/>
            <text-input
              type="number"
              step="1"
              class="w-11/12"
              inputClass="focus:outline-none"
              id="garage_parking_fee"
              :disabled="!variables.GARAGE_PARKING_FEE.active"
              v-model="garage_parking_fee"
              @change="handleChange(
                variables.GARAGE_PARKING_FEE,
                `$${garage_parking_fee}`,
                variables.GARAGE_PARKING_FEE.active
              )"
            />
          </span>
          <span class="form-hint">this is a monthly garage paking fee of your community.</span>
          <span v-if="hasError('garage_parking_fee')" class="form-error">{{ showError('garage_parking_fee') }}</span>
        </div>
        <div class="form-col">
          <label for="tech_package_fee">
            <input
              type="checkbox"
              class="mr-1 w-4 h-4"
              :checked="variables.TECH_PACKAGE_FEE.active"
              @change="() => {
                variables.TECH_PACKAGE_FEE.active = !variables.TECH_PACKAGE_FEE.active;
                handleChange(
                  variables.TECH_PACKAGE_FEE,
                  `$${tech_package_fee}`,
                  variables.TECH_PACKAGE_FEE.active
                );
              }"
            />
            monthly smart technology fee </label>
          <span class="inputField">
            <text-input value="$" readOnly/>
            <text-input
              type="number"
              step="1"
              class="w-11/12"
              inputClass="focus:outline-none"
              id="tech_package_fee"
              :disabled="!variables.TECH_PACKAGE_FEE.active"
              v-model="tech_package_fee"
              @change="handleChange(
                variables.TECH_PACKAGE_FEE,
                `$${tech_package_fee}`,
                variables.TECH_PACKAGE_FEE.active
              )"
            />
          </span>
          <span class="form-hint">this is a monthly smart technology fee of your community.</span>
          <span v-if="hasError('tech_package_fee')" class="form-error">{{ showError('tech_package_fee') }}</span>
        </div>
        <div class="form-col">
          <label for="move_in_fee">
            <input
              type="checkbox"
              class="mr-1 w-4 h-4"
              :checked="variables.MOVE_IN_FEE.active"
              @change="() => {
                variables.MOVE_IN_FEE.active = !variables.MOVE_IN_FEE.active;
                handleChange(
                  variables.MOVE_IN_FEE,
                  `$${move_in_fee}`,
                  variables.MOVE_IN_FEE.active
                );
              }"
            />
            move-in fee </label>
          <span class="inputField">
            <text-input value="$" readOnly/>
            <text-input
              type="number"
              step="1"
              id="move_in_fee"
              class="w-11/12"
              inputClass="focus:outline-none"
              :disabled="!variables.MOVE_IN_FEE.active"
              v-model="move_in_fee"
              @change="handleChange(
                variables.MOVE_IN_FEE,
                `$${move_in_fee}`,
                variables.MOVE_IN_FEE.active
              )"
            />
          </span>
          <span class="form-hint">this is a move-in fee of your community.</span>
          <span v-if="hasError('move_in_fee')" class="form-error">{{ showError('move_in_fee') }}</span>
        </div>
      </div>
      <!-- This will remain commented until Jason says otherwise. -->
      <!-- <div class="form-row">
        <div class="form-col">
          <label for="late_fee_amount">late fee amount</label>
          <span class="form-input inputField w-full">
            <input
              type="number"
              class="w-11/12 focus:outline-none"
              id="late_fee_amount"
              v-model="late_fee_amount"
              @change="handleChange(
                variables.LATE_FEE_AMOUNT,
                `${late_fee_amount}%`,
              )" />
            %
          </span>
          <span class="form-hint">this is the late fee of your community.</span>
          <span v-if="hasError('late_fee_amount')" class="form-error">{{ showError('late_fee_amount') }}</span>
        </div>
        <div class="form-col">
          <label for="late_fee_date">late fee date </label>
          <input
            type="number"
            class="form-input inputField focus:outline-none"
            id="late_fee_date"
            v-model="late_fee_date"
            @change="handleChange(
              variables.LATE_FEE_DATE,
              late_fee_date,
            )" />
          <span class="form-hint">this is the late fee date of your community.</span>
          <span v-if="hasError('late_fee_date')" class="form-error">{{ showError('late_fee_date') }}</span>
        </div>
      </div> -->
      <div class="form-row">
        <div class="form-col">
          <label for="landscaping_fee">
            <input
              type="checkbox"
              class="mr-1 w-4 h-4"
              :checked="variables.LANDSCAPING_FEE.active"
              @change="() => {
                variables.LANDSCAPING_FEE.active = !variables.LANDSCAPING_FEE.active;
                handleChange(
                  variables.LANDSCAPING_FEE,
                  `$${landscaping_fee}`,
                  variables.LANDSCAPING_FEE.active
                );
              }"
            />
            monthly landscaping fee </label>
          <span class="inputField">
            <text-input value="$" readOnly/>
            <text-input
              type="number"
              step="1"
              id="landscaping_fee"
              class="w-11/12"
              inputClass="focus:outline-none"
              :disabled="!variables.LANDSCAPING_FEE.active"
              v-model="landscaping_fee"
              @change="handleChange(
                variables.LANDSCAPING_FEE,
                `$${landscaping_fee}`,
                variables.LANDSCAPING_FEE.active
              )"
            />
          </span>
          <span class="form-hint">this is a monthly landscaping fee of your community.</span>
          <span v-if="hasError('landscaping_fee')" class="form-error">{{ showError('landscaping_fee') }}</span>
        </div>
        <div class="form-col">
          <label for="cable_television_fee">
            <input
              type="checkbox"
              class="mr-1 w-4 h-4"
              :checked="variables.CABLE_TELEVISION_FEE.active"
              @change="() => {
                variables.CABLE_TELEVISION_FEE.active = !variables.CABLE_TELEVISION_FEE.active;
                handleChange(
                  variables.CABLE_TELEVISION_FEE,
                  `$${cable_television_fee}`,
                  variables.CABLE_TELEVISION_FEE.active
                );
              }"
            />
            monthly cable television fee </label>
          <span class="inputField">
            <text-input value="$" readOnly/>
            <text-input
              type="number"
              step="1"
              id="cable_television_fee"
              class="w-11/12"
              inputClass="focus:outline-none"
              :disabled="!variables.CABLE_TELEVISION_FEE.active"
              v-model="cable_television_fee"
              @change="handleChange(
                variables.CABLE_TELEVISION_FEE,
                `$${cable_television_fee}`,
                variables.CABLE_TELEVISION_FEE.active
              )"
            />
          </span>
          <span class="form-hint">this is a monthly cable television fee of your community.</span>
          <span v-if="hasError('cable_television_fee')" class="form-error">{{ showError('cable_television_fee') }}</span>
        </div>
        <div class="form-col">
          <label for="electric_fee">
            <input
              type="checkbox"
              class="mr-1 w-4 h-4"
              :checked="variables.ELECTRIC_FEE.active"
              @change="() => {
                variables.ELECTRIC_FEE.active = !variables.ELECTRIC_FEE.active;
                handleChange(
                  variables.ELECTRIC_FEE,
                  `$${electric_fee}`,
                  variables.ELECTRIC_FEE.active
                );
              }"
            />
            monthly electric fee </label>
          <span class="inputField">
            <text-input value="$" readOnly/>
            <text-input
              type="number"
              step="1"
              id="electric_fee"
              :disabled="!variables.ELECTRIC_FEE.active"
              class="w-11/12"
              inputClass="focus:outline-none"
              v-model="electric_fee"
              @change="handleChange(
                variables.ELECTRIC_FEE,
                `$${electric_fee}`,
                variables.ELECTRIC_FEE.active
              )"
            />
          </span>
          <span class="form-hint">this is a monthly electric fee of your community.</span>
          <span v-if="hasError('electric_fee')" class="form-error">{{ showError('electric_fee') }}</span>
        </div>
      </div>
      <div class="form-row">
        <div class="form-col">
          <label for="pet_fee">
            <input
              type="checkbox"
              class="mr-1 w-4 h-4"
              :checked="variables.PET_FEE.active"
              @change="() => {
                variables.PET_FEE.active = !variables.PET_FEE.active;
                handleChange(
                  variables.PET_FEE,
                  `$${pet_fee}`,
                  variables.PET_FEE.active
                );
              }"
            />
            one-time pet fee </label>
          <span class="inputField">
            <text-input value="$" readOnly/>
            <text-input
              type="number"
              step="1"
              id="pet_fee"
              class="w-11/12"
              inputClass="focus:outline-none"
              :disabled="!variables.PET_FEE.active"
              v-model="pet_fee"
              @change="handleChange(
                variables.PET_FEE,
                `$${pet_fee}`,
                variables.PET_FEE.active
              )"
            />
          </span>
          <span class="form-hint">this is a one-time pet fee of your community.</span>
          <span v-if="hasError('pet_fee')" class="form-error">{{ showError('pet_fee') }}</span>
        </div>
        <div class="form-col">
          <label for="pet_rent_fee">
            <input
              type="checkbox"
              class="mr-1 w-4 h-4"
              :checked="variables.PET_RENT_FEE.active"
              @change="() => {
                variables.PET_RENT_FEE.active = !variables.PET_RENT_FEE.active;
                handleChange(
                  variables.PET_RENT_FEE,
                  `$${pet_rent_fee}`,
                  variables.PET_RENT_FEE.active
                );
              }"
            />
            monthly pet rent</label>
          <span class="inputField">
            <text-input value="$" readOnly/>
            <text-input
              type="number"
              step="1"
              id="pet_rent_fee"
              :disabled="!variables.PET_RENT_FEE.active"
              class="w-11/12"
              inputClass="focus:outline-none"
              v-model="pet_rent_fee"
              @change="handleChange(
                variables.PET_RENT_FEE,
                `$${pet_rent_fee}`,
                variables.PET_RENT_FEE.active
              )"
            />
          </span>
          <span class="form-hint">this is a monthly pet rent fee of your community.</span>
          <span v-if="hasError('pet_rent_fee')" class="form-error">{{ showError('pet_rent_fee') }}</span>
        </div>
        <div class="form-col">
          <label for="trash_fee">
            <input
              type="checkbox"
              class="mr-1 w-4 h-4"
              :checked="variables.TRASH_FEE.active"
              @change="() => {
                variables.TRASH_FEE.active = !variables.TRASH_FEE.active;
                handleChange(
                  variables.TRASH_FEE,
                  `$${trash_fee}`,
                  variables.TRASH_FEE.active
                );
              }"
            />
            monthly trash fee </label>
          <span class="inputField">
            <text-input value="$" readOnly/>
            <text-input
              type="number"
              step="1"
              :disabled="!variables.TRASH_FEE.active"
              id="trash_fee"
              class="w-11/12"
              inputClass="focus:outline-none"
              v-model="trash_fee"
              @change="handleChange(
                variables.TRASH_FEE,
                `$${trash_fee}`,
                variables.TRASH_FEE.active
              )"
            />
          </span>
          <span class="form-hint">this is a monthly trash fee of your community.</span>
          <span v-if="hasError('trash_fee')" class="form-error">{{ showError('trash_fee') }}</span>
        </div>
      </div>
      <div class="form-row">
        <div class="form-col">
          <label for="pest_control_fee">
            <input
              type="checkbox"
              class="mr-1 w-4 h-4"
              :checked="variables.PEST_CONTROL_FEE.active"
              @change="() => {
                variables.PEST_CONTROL_FEE.active = !variables.PEST_CONTROL_FEE.active;
                handleChange(
                  variables.PEST_CONTROL_FEE,
                  `$${pest_control_fee}`,
                  variables.PEST_CONTROL_FEE.active
                );
              }"
            />
            monthly pest control fee </label>
          <span class="inputField">
            <text-input value="$" readOnly/>
            <text-input
              type="number"
              step="1"
              id="pest_control_fee"
              :disabled="!variables.PEST_CONTROL_FEE.active"
              class="w-11/12"
              inputClass="focus:outline-none"
              v-model="pest_control_fee"
              @change="handleChange(
                variables.PEST_CONTROL_FEE,
                `$${pest_control_fee}`,
                variables.PEST_CONTROL_FEE.active
              )"
            />
          </span>
          <span class="form-hint">this is a monthly pest control fee of your community.</span>
          <span v-if="hasError('pest_control_fee')" class="form-error">{{ showError('pest_control_fee') }}</span>
        </div>
        <div class="form-col">
          <label for="water_fee">
            <input
              type="checkbox"
              class="mr-1 w-4 h-4"
              :checked="variables.WATER_FEE.active"
              @change="() => {
                variables.WATER_FEE.active = !variables.WATER_FEE.active;
                handleChange(
                  variables.WATER_FEE,
                  `$${water_fee}`,
                  variables.WATER_FEE.active
                );
              }"
            />
            monthly water fee </label>
          <span class="inputField">
            <text-input value="$" readOnly/>
            <text-input
              type="number"
              step="1"
              id="water_fee"
              :disabled="!variables.WATER_FEE.active"
              class="w-11/12"
              inputClass="focus:outline-none"
              v-model="water_fee"
              @change="handleChange(
                variables.WATER_FEE,
                `$${water_fee}`,
                variables.WATER_FEE.active
              )"
            />
          </span>
          <span class="form-hint">this is a monthly pest control fee of your community.</span>
          <span v-if="hasError('water_fee')" class="form-error">{{ showError('water_fee') }}</span>
        </div>
        <div class="form-col">
          <label for="utilities_fee">
            <input
              type="checkbox"
              class="mr-1 w-4 h-4"
              :checked="variables.UTILITIES_FEE.active"
              @change="() => {
                variables.UTILITIES_FEE.active = !variables.UTILITIES_FEE.active;
                handleChange(
                  variables.UTILITIES_FEE,
                  `$${utilities_fee}`,
                  variables.UTILITIES_FEE.active
                );
              }"
            />
            monthly utilities fee </label>
          <span class="inputField">
            <text-input value="$" readOnly/>
            <text-input
              type="number"
              step="1"
              :disabled="!variables.UTILITIES_FEE.active"
              id="utilities_fee"
              class="w-11/12"
              inputClass="focus:outline-none"
              v-model="utilities_fee"
              @change="handleChange(
                variables.UTILITIES_FEE,
                `$${utilities_fee}`,
                variables.UTILITIES_FEE.active
              )"
            />
          </span>
          <span class="form-hint">this is a monthly utilities fee of your community.</span>
          <span v-if="hasError('utilities_fee')" class="form-error">{{ showError('utilities_fee') }}</span>
        </div>
      </div>
      <div class="form-row">
        <div class="form-col">
          <label for="internet_fee">
            <input
              type="checkbox"
              class="mr-1 w-4 h-4"
              :checked="variables.INTERNET_FEE.active"
              @change="() => {
                variables.INTERNET_FEE.active = !variables.INTERNET_FEE.active;
                handleChange(
                  variables.INTERNET_FEE,
                  `$${internet_fee}`,
                  variables.INTERNET_FEE.active
                );
              }"
            />
            monthly internet fee </label>
          <span class="inputField">
            <text-input value="$" readOnly/>
            <text-input
              type="number"
              step="1"
              :disabled="!variables.INTERNET_FEE.active"
              id="internet_fee"
              class="w-11/12"
              inputClass="focus:outline-none"
              v-model="internet_fee"
              @change="handleChange(
                variables.INTERNET_FEE,
                `$${internet_fee}`,
                variables.INTERNET_FEE.active
              )"
            />
          </span>
          <span class="form-hint">this is a monthly internet fee of your community.</span>
          <span v-if="hasError('internet_fee')" class="form-error">{{ showError('internet_fee') }}</span>
        </div>
        <div class="form-col">
          <label for="elevator_fee">
            <input
              type="checkbox"
              class="mr-1 w-4 h-4"
              :checked="variables.ELEVATOR_FEE.active"
              @change="() => {
                variables.ELEVATOR_FEE.active = !variables.ELEVATOR_FEE.active;
                handleChange(
                  variables.ELEVATOR_FEE,
                  `$${elevator_fee}`,
                  variables.ELEVATOR_FEE.active
                );
              }"
            />
            monthly elevator fee </label>
          <span class="inputField">
            <text-input value="$" readOnly/>
            <text-input
              type="number"
              step="1"
              :disabled="!variables.ELEVATOR_FEE.active"
              id="elevator_fee"
              class="w-11/12"
              inputClass="focus:outline-none"
              v-model="elevator_fee"
              @change="handleChange(
                variables.ELEVATOR_FEE,
                `$${elevator_fee}`,
                variables.ELEVATOR_FEE.active
              )"
            />
          </span>
          <span class="form-hint">this is a monthly elevator fee of your community.</span>
          <span v-if="hasError('elevator_fee')" class="form-error">{{ showError('elevator_fee') }}</span>
        </div>
        <div class="form-col">
          <label for="valet_trash_fee">
            <input
              type="checkbox"
              class="mr-1 w-4 h-4"
              :checked="variables.VALET_TRASH_FEE.active"
              @change="() => {
                variables.VALET_TRASH_FEE.active = !variables.VALET_TRASH_FEE.active;
                handleChange(
                  variables.VALET_TRASH_FEE,
                  `$${valet_trash_fee}`,
                  variables.VALET_TRASH_FEE.active
                );
              }"
            />
            monthly valet trash fee </label>
          <span class="inputField">
            <text-input value="$" readOnly/>
            <text-input
              type="number"
              step="1"
              :disabled="!variables.VALET_TRASH_FEE.active"
              id="valet_trash_fee"
              class="w-11/12"
              inputClass="focus:outline-none"
              v-model="valet_trash_fee"
              @change="handleChange(
                variables.VALET_TRASH_FEE,
                `$${valet_trash_fee}`,
                 variables.VALET_TRASH_FEE.active
              )"
            />
          </span>
          <span class="form-hint">this is a monthly valet trash fee of your community.</span>
          <span v-if="hasError('valet_trash_fee')" class="form-error">{{ showError('valet_trash_fee') }}</span>
        </div>
      </div>
      <div class="form-row">
        <div class="inputWidth">
          <p class="font-bold text-sm mb-2" for="security_deposit">
            <input
              type="checkbox"
              class="mr-1 w-4 h-4"
              :checked="variables.SECURITY_DEPOSIT_AMOUNT_FEE.active"
              @change="() => {
                variables.SECURITY_DEPOSIT_AMOUNT_FEE.active = !variables.SECURITY_DEPOSIT_AMOUNT_FEE.active;
                handleChange(
                  variables.SECURITY_DEPOSIT_AMOUNT_FEE,
                  `$${security_deposit}`,
                  variables.SECURITY_DEPOSIT_AMOUNT_FEE.active
                );
              }"
            />
            one-time security deposit</p>
          <span class="inputField">
            <text-input value="$" readOnly/>
            <text-input
              type="number"
              step="1"
              :disabled="!variables.SECURITY_DEPOSIT_AMOUNT_FEE.active"
              id="security_deposit"
              class="w-11/12"
              inputClass="focus:outline-none"
              v-model="security_deposit"
              @change="handleChange(
                variables.SECURITY_DEPOSIT_AMOUNT_FEE,
                `$${security_deposit}`,
                 variables.SECURITY_DEPOSIT_AMOUNT_FEE.active
              )"
            />
          </span>
          <span class="form-hint">this is a one time security deposit fee of your community.</span>
          <span v-if="hasError('security_deposit')" class="form-error">{{ showError('security_deposit') }}</span>
        </div>
      </div>
    </div>
    <modal-footer
      :footer="footer"
      :primary="updateSettings"
    ></modal-footer>
  </form>
</template>

<script>
import { i18n } from '@/i18n'
import { mapGetters } from 'vuex'
import AuthMixin from '@/components/auth/AuthMixin'
import ModalNavigation from '@/mixins/ModalNavigation'
import NotifyMixin from '@/mixins/NotifyMixin'
import Loader from '@/components/ui/Loader'
import ModalFooter from "@/components/ui/modals/ModalFooter"
import TextInput from '@/components/ui/TextInput';

export default {
  mixins: [AuthMixin, ModalNavigation, NotifyMixin],
  components: { Loader, ModalFooter, TextInput },
  data() {
    return {
      loading: false,
      aplication_fee: null,
      administration_fee: null,
      reserved_parking_fee: null,
      garage_parking_fee: null,
      tech_package_fee: null,
      late_fee_amount: null,
      late_fee_date: null,
      pet_fee: null,
      pet_rent_fee: null,
      landscaping_fee: null,
      cable_television_fee: null,
      move_in_fee: null,
      electric_fee: null,
      trash_fee: null,
      pest_control_fee: null,
      water_fee: null,
      utilities_fee: null,
      internet_fee: null,
      elevator_fee: null,
      valet_trash_fee: null,
      security_deposit: null,
      variables: {
        APPLICATION_FEE: {},
        ADMINISTRATION_FEE: {},
        RESERVED_PARKING_FEE: {},
        GARAGE_PARKING_FEE: {},
        TECH_PACKAGE_FEE: {},
        LATE_FEE_AMOUNT: {},
        LATE_FEE_DATE: {},
        PET_FEE: {},
        PET_RENT_FEE: {},
        LANDSCAPING_FEE: {},
        CABLE_TELEVISION_FEE: {},
      },
      fees: [],
      fieldsToUpdate: [],
      footer: {
        primaryButton: 'save'
      },
    }
  },
  validations: {
    'aplication_fee': 'required | less_than_zero | only_numbers | two_decimals',
    'administration_fee': 'required | less_than_zero | only_numbers | two_decimals',
    'reserved_parking_fee': 'required | less_than_zero | only_numbers | two_decimals',
    'garage_parking_fee': 'required | less_than_zero | only_numbers | two_decimals',
    'landscaping_fee': 'required | less_than_zero | only_numbers | two_decimals',
    'cable_television_fee': 'required | less_than_zero | only_numbers | two_decimals',
    'tech_package_fee': 'required | less_than_zero | only_numbers | two_decimals',
    // 'late_fee_amount': 'required', This will remain commented until Jason says otherwise.
    // 'late_fee_date': 'required', This will remain commented until Jason says otherwise.
    'pet_fee': 'required | less_than_zero | only_numbers | two_decimals',
    'pet_rent_fee': 'required | less_than_zero | only_numbers | two_decimals',
    'move_in_fee': 'required | less_than_zero | only_numbers | two_decimals',
    'electric_fee': 'required | less_than_zero | only_numbers | two_decimals',
    'trash_fee': 'required | less_than_zero | only_numbers | two_decimals',
    'pest_control_fee': 'required | less_than_zero | only_numbers | two_decimals',
    'water_fee': 'required | less_than_zero | only_numbers | two_decimals',
    'utilities_fee': 'required | less_than_zero | only_numbers | two_decimals',
    'internet_fee': 'required | less_than_zero | only_numbers | two_decimals',
    'valet_trash_fee': 'required | less_than_zero | only_numbers | two_decimals',
    'security_deposit': 'required | less_than_zero | only_numbers | two_decimals'
  },
  computed: {
    ...mapGetters({
      backModal: 'modals/getLastModal',
    }),
  },
  watch: {
      community: {
        handler: function() {
          if (this.community) this.fetchAllFeesSettings();
        },
        immediate: true
      },
    },
  methods: {
    loadFees(fees) {
      this.aplication_fee = fees?.APPLICATION_FEE.setting_value ? fees?.APPLICATION_FEE.setting_value.replace('$', '') : '0';
      this.administration_fee = fees?.ADMINISTRATION_FEE.setting_value ? fees?.ADMINISTRATION_FEE.setting_value.replace('$', '') : '0';
      this.reserved_parking_fee = fees?.RESERVED_PARKING_FEE.setting_value ? fees?.RESERVED_PARKING_FEE.setting_value.replace('$', '') : '0';
      this.garage_parking_fee = fees?.GARAGE_PARKING_FEE.setting_value ? fees?.GARAGE_PARKING_FEE.setting_value.replace('$', '') : '0';
      this.tech_package_fee = fees?.TECH_PACKAGE_FEE.setting_value ? fees?.TECH_PACKAGE_FEE.setting_value.replace('$', '') : '0';
      // this.late_fee_amount = fees?.LATE_FEE_AMOUNT.setting_value ? fees?.LATE_FEE_AMOUNT.setting_value.replace('%', '') : '0'; This will remain commented until Jason says otherwise.
      // this.late_fee_date = fees?.LATE_FEE_DATE.setting_value; This will remain commented until Jason says otherwise.
      this.pet_fee = fees?.PET_FEE.setting_value ? fees?.PET_FEE.setting_value.replace('$', '') : '0';
      this.landscaping_fee = fees?.LANDSCAPING_FEE.setting_value ? fees?.LANDSCAPING_FEE.setting_value.replace('$', '') : '0';
      this.cable_television_fee = fees?.CABLE_TELEVISION_FEE.setting_value ? fees?.CABLE_TELEVISION_FEE.setting_value.replace('$', '') : '0';
      this.pet_rent_fee = fees?.PET_RENT_FEE?.setting_value ? fees?.PET_RENT_FEE?.setting_value.replace('$', '') : '0';
      this.move_in_fee = fees?.MOVE_IN_FEE?.setting_value ? fees?.MOVE_IN_FEE?.setting_value.replace('$', '') : '0';
      this.electric_fee = fees?.ELECTRIC_FEE?.setting_value ? fees?.ELECTRIC_FEE?.setting_value.replace('$', '') : '0';
      this.trash_fee = fees?.TRASH_FEE?.setting_value ? fees?.TRASH_FEE?.setting_value.replace('$', '') : '0';
      this.pest_control_fee = fees?.PEST_CONTROL_FEE?.setting_value ? fees?.PEST_CONTROL_FEE?.setting_value.replace('$', '') : '0';
      this.water_fee = fees?.WATER_FEE?.setting_value ? fees?.WATER_FEE?.setting_value.replace('$', '') : '0';
      this.utilities_fee = fees?.UTILITIES_FEE?.setting_value ? fees?.UTILITIES_FEE?.setting_value.replace('$', '') : '0';
      this.internet_fee = fees?.INTERNET_FEE?.setting_value? fees?.INTERNET_FEE?.setting_value.replace('$', '') : '0';
      this.elevator_fee = fees?.ELEVATOR_FEE?.setting_value ? fees?.ELEVATOR_FEE?.setting_value.replace('$', '') : '0';
      this.valet_trash_fee = fees?.VALET_TRASH_FEE?.setting_value ? fees?.VALET_TRASH_FEE?.setting_value.replace('$', '') : '0';
      this.security_deposit = fees?.SECURITY_DEPOSIT_AMOUNT_FEE?.setting_value ? fees?.SECURITY_DEPOSIT_AMOUNT_FEE?.setting_value.replace('$', '') : '0';
    },
    fetchAllFeesSettings() {
      this.loading = true;
      this.$dhDataProvider.getList('allFeesSettings', {communityId: this.community?.id })
        .then((res) => {
          this.variables = res;
          this.loadFees(res)
        })
        .catch((error) => this.notifyError(error.message))
        .finally(() => this.loading = false)
    },
    handleChange(variable, setting_value, active=false) {
      if (variable) {

        if(this.fieldsToUpdate.find(x => x.id_community_setting_value === variable.id_community_setting_value))
        {
          this.fieldsToUpdate.splice(
            this.fieldsToUpdate.findIndex(
              ({id_community_setting_value}) => id_community_setting_value == variable.id_community_setting_value
            ) , 1
          )
        }

        const {id_community_setting_value} = variable;
        if (id_community_setting_value) this.fieldsToUpdate = [...this.fieldsToUpdate, { id_community_setting_value, setting_value, active }];

     }
    },
    updateSettings(e) {
      e.preventDefault();
      if (this.validator.passes()) {
        this.loading = true;
        this.same_as_community_name = false
        if (!this.fieldsToUpdate.length) {
          this.loading = false;
        }

        this.$dhDataProvider.update('communitySetting', {id: this.fieldsToUpdate[0].id_community_setting_value,
        data: this.fieldsToUpdate})
        .then((response) => {
          if (response.status === 200) {
            this.loading = false;
            this.notifySuccess('The setting was updated successfully')
          }
        })
        .catch((error) => this.notifyError(error.message))
      }
    },
  },
  created() {
    this.initValidator();
    this.validator.extend('less_than_zero', function(value) {
      return !(value < 0);
    });
    this.validator.extend('only_numbers', function(value) {
      let isOkay = true;
      if (value.includes('e') || value.includes('=') || value.includes('-') || value.includes('+')) isOkay = false;
      return isOkay;
    });
    this.validator.extend('two_decimals', function(value) {
      const max = 2;
      return !(value.toString().split('.')?.[1]?.length > max ? i18n.global.t('validation.max_decimal_places', {max}) : undefined);
    });
    this.validator.setCustomErrorMessages({
      'aplication_fee.less_than_zero': 'Enter only positive values.',
      'aplication_fee.only_numbers': 'Enter only numbers.',
      'aplication_fee.two_decimals': 'No more than two decimals.',
      'administration_fee.less_than_zero': 'Enter only positive values.',
      'administration_fee.only_numbers': 'Enter only numbers.',
      'administration_fee.two_decimals': 'No more than two decimals.',
      'reserved_parking_fee.less_than_zero': 'Enter only positive values.',
      'reserved_parking_fee.only_numbers': 'Enter only numbers.',
      'reserved_parking_fee.two_decimals': 'No more than two decimals.',
      'garage_parking_fee.less_than_zero': 'Enter only positive values.',
      'garage_parking_fee.only_numbers': 'Enter only numbers.',
      'garage_parking_fee.two_decimals': 'No more than two decimals.',
      'landscaping_fee.less_than_zero': 'Enter only positive values.',
      'landscaping_fee.only_numbers': 'Enter only numbers.',
      'landscaping_fee.two_decimals': 'No more than two decimals.',
      'cable_television_fee.less_than_zero': 'Enter only positive values.',
      'cable_television_fee.only_numbers': 'Enter only numbers.',
      'cable_television_fee.two_decimals': 'No more than two decimals.',
      'tech_package_fee.less_than_zero': 'Enter only positive values.',
      'tech_package_fee.only_numbers': 'Enter only numbers.',
      'tech_package_fee.two_decimals': 'No more than two decimals.',
      'pet_fee.less_than_zero': 'Enter only positive values.',
      'pet_fee.only_numbers': 'Enter only numbers.',
      'pet_fee.two_decimals': 'No more than two decimals.',
      'pet_rent_fee.less_than_zero': 'Enter only positive values.',
      'pet_rent_fee.only_numbers': 'Enter only numbers.',
      'pet_rent_fee.two_decimals': 'No more than two decimals.',
      'move_in_fee.less_than_zero': 'Enter only positive values.',
      'move_in_fee.only_numbers': 'Enter only numbers.',
      'move_in_fee.two_decimals': 'No more than two decimals.',
      'electric_fee.less_than_zero': 'Enter only positive values.',
      'electric_fee.only_numbers': 'Enter only numbers.',
      'electric_fee.two_decimals': 'No more than two decimals.',
      'trash_fee.less_than_zero': 'Enter only positive values.',
      'trash_fee.only_numbers': 'Enter only numbers.',
      'trash_fee.two_decimals': 'No more than two decimals.',
      'pest_control_fee.less_than_zero': 'Enter only positive values.',
      'pest_control_fee.only_numbers': 'Enter only numbers.',
      'pest_control_fee.two_decimals': 'No more than two decimals.',
      'water_fee.less_than_zero': 'Enter only positive values.',
      'water_fee.only_numbers': 'Enter only numbers.',
      'water_fee.two_decimals': 'No more than two decimals.',
      'utilities_fee.less_than_zero': 'Enter only positive values.',
      'utilities_fee.only_numbers': 'Enter only numbers.',
      'utilities_fee.two_decimals': 'No more than two decimals.',
      'internet_fee.less_than_zero': 'Enter only positive values.',
      'internet_fee.only_numbers': 'Enter only numbers.',
      'internet_fee.two_decimals': 'No more than two decimals.',
      'valet_trash_fee.less_than_zero': 'Enter only positive values.',
      'valet_trash_fee.only_numbers': 'Enter only numbers.',
      'valet_trash_fee.two_decimals': 'No more than two decimals.',
      'security_deposit.less_than_zero': 'Enter only positive values.',
      'security_deposit.only_numbers': 'Enter only numbers.',
      'security_deposit.two_decimals': 'No more than two decimals.',
    });
  }
}
</script>

<style scoped>
  .active {
    @apply text-blue-500;
  }
  .inputField {
    display: inline-block;
  }
  .buttonStyle {
    display: inline-block;
  }
  .inputWidth {
    width: 30%;
  }
  .boxSize {
    height: 630px;
    padding-bottom: 2rem;
  }
  [type="checkbox"]
  {
      vertical-align:middle;
  }
</style>
