<template>
  <form
    class="flex flex-col max-w-full"
    v-on:submit.prevent="updateSettings"
  >
    <loader :loading="loading" :backdrop="true" />
    <div class="h-full boxSize overflow-auto">
      <div class="form-row">
        <div class="w-1/2">
          <div class="form-col">
            <p class="mb-1 font-bold text-sm" for="community_name">
              community name
            </p>
            <text-input
              class="mt-2"
              id="community_name"
              v-model="community_name"
              @change="handleChange(community_name, 'community_name')"
            />
            <span class="form-hint"
              >this is the full name of your community.</span
            >
            <span v-if="hasError('community_name')" class="form-error">{{
              showError('community_name')
            }}</span>
          </div>
        </div>
        <div class="form-col">
          <label for="digital_human">Default Digital Human</label>
          <Dropdown
            v-model="digital_human_value"
            :searchable="false"
            label="label"
            :options="digital_human_options"
            @input="handleChange($event, 'digital_human_value')"
            objectMode
            noClear
          />
        </div>
      </div>
      <div class="form-row">
        <div class="w-1/2">
          <div class="form-col">
            <p class="mb-1 font-bold text-sm" for="community_name">
              community nickname
            </p>
            <text-input
              id="community_nickname"
              v-model="community_nickname"
              @change="handleChange(community_nickname, 'community_nickname')"
            />
            <span class="form-hint"
              >this is the short name of your community.</span
            >
            <span v-if="hasError('community_nickname')" class="form-error"
              >{{ showError('community_nickname') }}
            </span>
          </div>
        </div>
        <div class="form-col">
          <label for="language">Default Language</label>
          <Dropdown
            v-model="language_value"
            :searchable="false"
            label="label"
            :options="language_options"
            @input="handleChange($event, 'language_value')"
            objectMode
            noClear
          />
        </div>
      </div>
      <div class="form-row">
        <div class="w-1/2">
          <div class="form-col">
            <label for="email">general email address </label>
            <text-input
              id="email"
              v-model="community_email_address"
              @change="handleChange(community_email_address, 'community_email_address')"
            />
            <span class="form-hint"
              >this is the main email address of your community.</span
            >
            <span v-if="hasError('community_email_address')" class="form-error">{{
              showError('community_email_address')
            }}</span>
          </div>
        </div>
        <div class="form-col">
          <label for="phone">phone number </label>
          <text-input
            id="phone"
            v-model="community_phone_number"
            @change="handleChange(community_phone_number, 'community_phone_number')"
          />
          <span class="form-hint"
            >this is the primary phone number of your community.</span
          >
          <span v-if="hasError('community_phone_number')" class="form-error">{{
            showError('community_phone_number')
          }}</span>
        </div>
      </div>
      <div class="form-row flex items-center">
        <toggle-input
          id="is_available"
          :model-value="is_available"
          size="small"
          @update:modelValue="val => handleToggleChange(val)"
        />
        <p for="is_available" class="ml-2 text-black text-xs font-bold">Show only available floorplans</p>
      </div>
      <div class="form-row">
        <div class="form-col">
          <label class="form-section-title">hours of operation</label>
          <hours-of-operations :website-operation-hours="initHours" no-title disabled></hours-of-operations>
        </div>
      </div>
    </div>
    <modal-footer
      :footer="footer"
      :primary="updateSettings"
    ></modal-footer>
  </form>
</template>

<script>
  import AuthMixin from '@/components/auth/AuthMixin'
  import ModalNavigation from '@/mixins/ModalNavigation'
  import NotifyMixin from '@/mixins/NotifyMixin'
  import Loader from '@/components/ui/Loader'
  import ModalFooter from '@/components/ui/modals/ModalFooter'
  import HoursOfOperations from '@/components/operation_hours/HoursOfOperations';
  import TextInput from '@/components/ui/TextInput';
  import Dropdown from '@/components/ui/Dropdown';
  import ToggleInput from '@/components/ui/ToggleInput';

  export default {
    mixins: [AuthMixin, ModalNavigation, NotifyMixin],
    components: { Loader, ModalFooter, HoursOfOperations, Dropdown, TextInput, ToggleInput },
    data() {
      return {
        is_available: false,
        loading: false,
        community_name: '',
        community_nickname: '',
        community_phone_number: '',
        community_email_address: '',
        digital_human_value: null,
        digital_human_options: [
          {
            key: 'mia',
            label: 'Mia'
          },
          {
            key: 'jordan',
            label: 'Jordan'
          },
          {
            key: 'nico',
            label: 'Nico'
          },
        ],
        language_value: null,
        language_options: [
          {
            key: 'en',
            label: 'English'
          },
          {
            key: 'es',
            label: 'Spanish'
          },
        ],
        variables: {
          PROPERTY_LONG_NAME: {},
          PROPERTY_SHORT_NAME: {},
          PROPERTY_PHONE_NUMBER: {},
          PROPERTY_EMAIL: {},
          PROPERTY_DIGITAL_HUMAN: {},
          PROPERTY_LANGUAGE: {},
        },
        conversationVariables: [],
        communityHours: [],
        fieldsToUpdate: [],
        footer: {
          primaryButton: 'save',
        },
      }
    },
    validations: {
      community_name: 'required',
      community_nickname: 'required',
      community_phone_number: 'required',
      community_email_address: 'required',
    },
    computed: {
      initHours() {
        return this.communityHours?.map(({ ...record }) => ({ ...record })) || [];
      }
    },
    watch: {
      community: {
        handler: function() {
          if (this.community) {
            this.fetchAllCommunitySettings()
            this.fetchRecord()
          }
        },
        immediate: true
      },
    },
    methods: {
      fetchAllCommunitySettings() {

        this.loading = true;
        this.$dhDataProvider
          .getList('allVariablesSettings', { communityId: this.community?.id })
          .then((res) => {
            this.variables = res
            this.loadConversationVariables(res)
          })
          .catch((error) => this.notifyError(error.message))
          .finally(() => (this.loading = false))
      },
      fetchRecord() {
        this.loading = true;
        if(this.community?.id) {
          this.$dhDataProvider
            .getOne('getHoursOfOperations', {id: this.community.id})
            .then((res) => {
              if (!res) return;
              if (res.length > 0)
                this.communityHours = res;
            })
            .catch((error) => this.notifyError(error.message))
            .finally(() => (this.loading = false))
        }
      },

    handleChange(value, id){
      let newSetting;

      switch(id){
        case "digital_human_value":
          newSetting = {
            id_community_setting_value: this.variables.PROPERTY_DIGITAL_HUMAN ? this.variables.PROPERTY_DIGITAL_HUMAN.id_community_setting_value : null,
            setting_value: value.key,
            active: 'false'
          };
        break;
        case "community_name":
          newSetting = {
            id_community_setting_value: this.variables.PROPERTY_LONG_NAME ? this.variables.PROPERTY_LONG_NAME.id_community_setting_value : null,
            setting_value: this.community_name,
            active: 'false'
          };
        break;
        case "community_nickname":
          newSetting = {
            id_community_setting_value: this.variables.PROPERTY_SHORT_NAME ? this.variables.PROPERTY_SHORT_NAME.id_community_setting_value : null,
            setting_value: this.community_nickname,
            active: 'false'
          };
        break;
        case "language_value":
          newSetting = {
            id_community_setting_value: this.variables.PROPERTY_LANGUAGE ? this.variables.PROPERTY_LANGUAGE.id_community_setting_value : null,
            setting_value: value.key,
            active: 'false'
          };
        break;
        case "community_email_address":
          newSetting = {
            id_community_setting_value: this.variables.PROPERTY_EMAIL ? this.variables.PROPERTY_EMAIL.id_community_setting_value : null,
            setting_value: this.community_email_address,
            active: 'false'
          };
        break;
        case "community_phone_number":
          newSetting = {
            id_community_setting_value: this.variables.PROPERTY_PHONE_NUMBER ? this.variables.PROPERTY_PHONE_NUMBER.id_community_setting_value : null,
            setting_value: this.community_phone_number,
            active: 'false'
          };
        break;
      }

      const newFieldsToUpdate = this.fieldsToUpdate.findIndex(
        (item) => item.id_community_setting_value == newSetting.id_community_setting_value
      );

      if(newFieldsToUpdate < 0){
        this.fieldsToUpdate.push(newSetting);
      }else{
        this.fieldsToUpdate[newFieldsToUpdate] = newSetting;
      }
    },
      updateSettings() {
        if (this.validator.passes()) {
          if (!this.fieldsToUpdate.length) {
            return;
          }

          this.loading = true;

            this.$dhDataProvider.update('communitySetting', {id: this.fieldsToUpdate[0].id_community_setting_value,
            data: this.fieldsToUpdate})
            .then((response) => {
              if (response.status === 200) {
                this.loading = false;
                this.notifySuccess('The setting was updated successfully')
              }
            })
            .catch((error) => this.notifyError(error.message))
            .finally(() => {
                this.fieldsToUpdate = [];
                this.fetchAllCommunitySettings();
                this.fetchRecord();
                this.loading = false;
              })
        }
      },
      loadConversationVariables(conversationVariables) {

        let settingsThatNeedToBeUpdated = [];

        this.community_name =
          conversationVariables?.PROPERTY_LONG_NAME.setting_value
        this.community_nickname =
          conversationVariables?.PROPERTY_SHORT_NAME.setting_value
        this.community_phone_number =
          conversationVariables?.PROPERTY_PHONE_NUMBER.setting_value
        this.community_email_address =
          conversationVariables?.PROPERTY_EMAIL.setting_value

        if(conversationVariables?.PROPERTY_DIGITAL_HUMAN && conversationVariables?.PROPERTY_DIGITAL_HUMAN.setting_value){

          // Database return only the keys (jordan, mia)
          let digital_human_response =
            conversationVariables?.PROPERTY_DIGITAL_HUMAN.setting_value;

          // Gets the selected object from the select array using the response key
          this.digital_human_value = this.digital_human_options.find(option => option.key == digital_human_response);
        }else{
          this.digital_human_value = null;
          settingsThatNeedToBeUpdated.push(
            {
              setting_name: "{PROPERTY_DIGITAL_HUMAN}",
              setting_value: "mia",
            },          
          );          
        }

        if(conversationVariables?.PROPERTY_LANGUAGE && conversationVariables?.PROPERTY_LANGUAGE.setting_value){
          // Database return only the keys (en, es)
          let language_response =
            conversationVariables?.PROPERTY_LANGUAGE.setting_value

          this.language_value = this.language_options.find(option => option.key == language_response);
        }else{
          this.language_value = null;
          settingsThatNeedToBeUpdated.push(
            {
              setting_name: "{PROPERTY_LANGUAGE}",
              setting_value: "en",
            },          
          );   

        }

        if(!this.digital_human_value || !this.language_value){
          this.createSettings(settingsThatNeedToBeUpdated);
        }

      },
      createSettings(settingsThatNeedToBeUpdated) {
        this.loading = true;
        this.$dhDataProvider
          .create("communitySettings", {
            communityId: this.community?.id,
            data: {
              community_settings: settingsThatNeedToBeUpdated
            },
          })
          .then(() =>
            this.notifySuccess("General Settings were successfully created")
          )
          .catch((error) => this.notifyError(error.message))
          .finally(() => {
            this.fetchAllCommunitySettings();
            this.loading = false;
          });
        },
        handleToggleChange(enabled) {
          this.is_available = enabled;
        },   
    },
    created() {
      this.initValidator()
      this.fieldsToUpdate = [];
    },
  }
</script>

<style scoped>
.active {
  @apply text-blue-500;
}
.buttonStyle {
  display: inline-block;
}
.boxSize {
  height: 630px;
}
</style>
