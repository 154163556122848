<template>
  <div class="h-full flex flex-col">
    <loader :loading="loading" :backdrop="true" />
    <div class="flex flex-1 overflow-auto">
      <div class="w-3/12 overflow-auto border-r-2 pt-10 px-5 border-gray-400 text-black mr-12">
          <div class="tab" @click="selectTab(1)" v-bind:class="{ active: isActive && currentTab == 1 }">
            <p>general settings</p>
          </div>
          <div class="tab my-5" @click="selectTab(2)" v-bind:class="{ active: isActive && currentTab == 2 }">
            <p>property amenities</p>
          </div>
          <div class="tab my-5" @click="selectTab(3)" v-bind:class="{ active: isActive && currentTab == 3 }">
            <p>unit amenities</p>
          </div>
          <div class="tab my-5" @click="selectTab(4)" v-bind:class="{ active: isActive && currentTab == 4 }">
            <p>community fees</p>
          </div>
          <div class="tab" @click="selectTab(5)" v-bind:class="{ active: isActive && currentTab == 5 }">
            <p>banner creation</p>
          </div>
          <div v-if="community.id" class="mt-4 p-2" style="background: #c6deee;">
              <strong class="text-xs font-600">Community ID: </strong>
              <div class="text-xs my-2">{{community.id}}</div>
          </div>
          <div class="mt-8">
          <p class="active-text" v-if="currentTab === 2">
            Select which primary amenities are in your community. Then, select
            the secondary details that apply to each primary amenity.
          </p>

          <p class="active-text" v-if="currentTab === 3">
            Select which primary amenities are in your units. Then, select the
            secondary details that apply to each primary amenity. Note that some
            primary unit amenities are selected automatically. For these, you
            are still encouraged to select the appropriate secondary amenities.
          </p>
          <p class="active-text" v-if="currentTab === 4">
            First, select which fees apply to your community. <br />
            Next, input the amount of those fees. <br />
            Your digital human will use this information to answer questions
            about community fees. <br />
            You can fully customize your digital human's response by going into
            customize your conversation, then selecting FAQs as the main topic, and
            fees as the conversation detail, and finally selecting which fee you
            would like to customize in next level detail.
          </p>
          </div>
      </div>
      <div class="overflow-auto w-full">
        <div v-if="!community" class="mt-3 border border-blue-400 font-400 text-sm text-blue-800 font-frank px-4 py-6 bg-blue-200 text-center">
            <p>please select a community to continue.</p>
        </div>
        <div v-else>
          <ConversationVariables v-if="currentTab === 1" />
          
          <Amenities v-if="currentTab === 2" :key="currentTab" :alert=false :category="{
            description: 'Property Amenities',
            intent_category_name: 'Property Amenities',
          }" />
          <Amenities v-if="currentTab === 3" :key="currentTab" :alert=false :category="{
            description: 'Unit Amenities',
            intent_category_name: 'Unit Amenities',
          }" />
          <Fees v-if="currentTab === 4" />
          <Banner v-if="currentTab === 5" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AuthMixin from '@/components/auth/AuthMixin'
import ModalNavigation from '@/mixins/ModalNavigation'
import ConversationVariables from '@/views/digital_human/digital_human_settings/ConversationVariables.vue'
import Banner from '@/views/digital_human/digital_human_settings/Banner.vue'
import Fees from '@/views/digital_human/digital_human_settings/Fees.vue'
import Amenities from '@/views/digital_human/digital_human_settings/Amenities.vue'
import Loader from '@/components/ui/Loader'
import NotifyMixin from '@/mixins/NotifyMixin'

export default {
  mixins: [AuthMixin, ModalNavigation, NotifyMixin],
  components: {
    ConversationVariables,
    Fees,
    Banner,
    Amenities,
    Loader
  },
  data() {
    return {
      loading: false,
      currentTab: 0,
      isActive: true,
      currentCommunity: undefined,
    }
  },
  watch: {
    community: {
        handler: function() {
            this.currentCommunity = this.community;
        },
        immediate: true
    },
  },
  methods: {
    handleSetupRedirect() {
      this.$router.push( {name: 'setup-wizard.index'}).then( () => {});
    },
    selectTab(nextTab) {
      if (this.currentTab !== nextTab) {
        this.isActive = true;
        this.currentTab = nextTab;
      }
    },
    checkIfCommunityHaveAConversation(communityId) {
      this.loading = true;
      this.$dhDataProvider
        .getOne('communityHasConversation', { id: communityId })
        .then((res) => {
          if (!res.community_has_conversation) { 
            this.loading = false;
            this.handleSetupRedirect()
          } else {
            this.loading = false;
            this.selectTab(1);
          }
        })
        .catch((error) => this.notifyError(error.message))
    },
  },
  mounted() {
    if (this.community?.id) {
      this.checkIfCommunityHaveAConversation(this.community.id)
    }
  },
  created() {
    if (!this.community) {
      this.notifyError(
        'please select a community to continue, then refresh the browser'
      )
      this.$router.push({ name: 'bookmarks' }).then( () => {});
      return;  
    }
    this.initValidator();
    this.selectTab(0);
  },
}
</script>

<style scoped>
  .active {
    @apply text-blue-500;
    border-left: 4px solid lightskyblue;
  }
  .active-text{
     @apply text-blue-500;
  }
  .tab {
    padding: 5px;
    cursor: pointer;
  }
</style>
