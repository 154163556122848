import { createNamespacedHelpers } from 'vuex'

const { mapState, mapActions } = createNamespacedHelpers('manage_amenities')

export default {
  computed: mapState({
    amenityType: (state) => state.amenityType,
    amenities: (state) => state.amenities,
    amenityDetails: (state) => state.amenityDetails,
    checkedAmenities: (state) => state.checkedAmenities,
    checkedAmenityDetails: (state) => state.checkedAmenityDetails,
    changedState: (state) => state.changedState,
    isSetupCompleted: (state) => state.isSetupCompleted,
    amenity: (state) => state.amenity,
    loading: (state) => state.loading,
  }),
  methods: mapActions([
    'setAmenityType',
    'setAmenities',
    'setAmenitiesDetails',
    'setCheckedAmenities',
    'setCheckedAmenityDetails',
    'setChangedState',
    'setSetupCompleted',
    'setAmenity',
    'setLoading',
    'cleanState',
  ]),
}
